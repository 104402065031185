import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Nav from '../components/Nav/Nav'

const PhotoPage = () => (
  <Layout>
    <SEO title="Photos" keywords={[`gatsby`, `application`, `react`]} />
    <Nav />

    <ul>
      <li><a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/uv?hl=en&pb=!1s0x808fce4d61b6d09f%3A0xfa8cda97963d7f1c!3m1!7e115!4shttps%3A%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipO8xu-HbNoEwXThO8RHOxtMuRerOkd1rp5mS5bn%3Dw480-h320-k-no!5sthien%20long%20son%20-%20Google%20Search!15sCAQ&imagekey=!1e10!2sAF1QipO8xu-HbNoEwXThO8RHOxtMuRerOkd1rp5mS5bn&sa=X&ved=2ahUKEwjcwv_N3LTnAhXHmq0KHRxMArwQoiowGnoECA4QBg">Google Photos</a></li>
      <li><a target="_blank" rel="noopener noreferrer" href="https://www.yelp.com/biz_photos/the-dragon-mountain-thien-long-son-milpitas">Yelp Photos</a></li>
      <li><a href="/videos">Youtube Videos</a></li>
    </ul>

    <iframe
      src="https://gramho.com/explore-hashtag/thienlongson"
      width="100%"
      height="800"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Instagram Photos"
    >
      Loading…
    </iframe>
  </Layout>
)

export default PhotoPage
